import { Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-auto-open-menu',
  templateUrl: './auto-open-menu.component.html',
  styleUrls: ['./auto-open-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AutoOpenMenuComponent implements OnInit {
  timedOutCloser: any;
  @Output() openEvent = new EventEmitter<string>();

  constructor() { }

  mouseEnter(trigger: any) {
    if (this.timedOutCloser) {
      clearTimeout(this.timedOutCloser);
    }
    trigger.openMenu();
    this.openEvent.emit('f-back');
  }

  mouseLeave(trigger: any) {
    this.timedOutCloser = setTimeout(() => {
      trigger.closeMenu();
      this.openEvent.emit('');
    }, 50);
  }

  ngOnInit(): void {
  }

}
