<div class="p-5" *ngIf="State === STATES.PHASE1">
    <h2>Recuperação de Senha - Envio do Token</h2>
    <mat-card class="p-card mat-elevation-z0">
        <div class="container">
            <form [formGroup]="formGroup1" class="form">
                <div class="row">
                    <mat-form-field class="form-element col-12 col-lg-6">
                        <mat-label>E-mail</mat-label>
                        <input matInput placeholder="E-mail" formControlName="Email" readonly>
                    </mat-form-field>
                </div>
            </form>
        </div>
    </mat-card>
</div>
<div class="p-5" *ngIf="State === STATES.PHASE2">
    <h2>Recuperação de Senha - Verificação do Token</h2>
    <mat-card class="p-card mat-elevation-z0">
        <div class="container">
            <form [formGroup]="formGroup2" (ngSubmit)="onSubmitPhase2(formGroup2.getRawValue())" class="form">
                <div class="row">
                    <mat-form-field class="form-element col-12 col-lg-6">
                        <mat-label>E-mail</mat-label>
                        <input matInput placeholder="E-mail" formControlName="Email" readonly>
                    </mat-form-field>
                </div>
                <div class="row">
                    <mat-form-field class="form-element col-12 col-lg-6">
                        <mat-label>Nova Senha</mat-label>
                        <input matInput placeholder="NovaSenha" #novaSenha2 formControlName="NovaSenha" type="password"
                            (change)="this.clearConfirmacaoNovaSenha()" maxlength="30">
                        <mat-error
                            *ngIf="!formGroup2.controls['NovaSenha'].valid && formGroup2.controls['NovaSenha'].touched">
                            {{ getErrorNovaSenha() }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="row">
                    <mat-form-field class="form-element col-12 col-lg-6">
                        <mat-label>Confirmação da Nova Senha</mat-label>
                        <input matInput placeholder="Confirmação da Nova Senha" formControlName="ConfirmacaoNovaSenha"
                            type="password" maxlength="30">
                        <mat-error
                            *ngIf="!formGroup2.controls['ConfirmacaoNovaSenha'].valid && formGroup2.controls['ConfirmacaoNovaSenha'].touched">
                            {{ getErrorConfirmacaoNovaSenha() }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="row">
                    <mat-form-field class="form-element col-12 col-lg-6">
                        <mat-label>Token</mat-label>
                        <input matInput placeholder="Token" formControlName="Token" maxlength="50">
                        <mat-error *ngIf="!formGroup2.controls['Token'].valid && formGroup2.controls['Token'].touched">
                            {{ getErrorToken() }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="row">
                    <div
                        class="form-element col-12 col-lg-3 d-flex justify-content-center justify-content-lg-start mt-3">
                        <button mat-raised-button color="disabled" class="button" type="button" [routerLink]="['/']"
                            routerLinkActive="router-link-active" [disabled]="processing">Cancelar
                        </button>
                    </div>
                    <div
                        class="form-element col-12 col-lg-3 d-flex flex-lg-row-reverse justify-content-center justify-content-lg-start mt-3">
                        <button mat-raised-button color="primary" type="submit" class="button"
                            [disabled]="!formGroup2.valid || processing">Alterar
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </mat-card>
</div>