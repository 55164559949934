import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { of, Observable } from 'rxjs';
import { CheckAccountRequest, RegisterPasswordRequest } from 'src/auth/auth.model';
import { AuthService } from 'src/auth/auth.service';
import { AlertService } from 'src/shared/components/alert/alert.service';

@Component({
  selector: 'app-account-activation',
  templateUrl: './account-activation.component.html',
  styleUrls: ['./account-activation.component.scss']
})
export class AccountActivationComponent implements OnInit {
  @ViewChild('novaSenha1') novaSenha1Element: ElementRef | undefined;

  formGroup1: FormGroup;
  processing = false;


  passwordComplexity = (control: AbstractControl): Observable<ValidationErrors | null> => {
    return of(this.authService.validatePasswordComplexity(control.value) ? null : { notComplex: true })

  }
  comparePasswords = (): Observable<ValidationErrors | null> => {
    return of(this.formGroup1.controls.NovaSenha.value === this.formGroup1.controls.ConfirmacaoNovaSenha.value ? null : { notSame: true });
  }


  private encodedToken: string | undefined;
  private email: string | undefined;
  private token: string | undefined;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly formBuilder: FormBuilder,
    private readonly authService: AuthService,
    private readonly _alert: AlertService,
  ) {
    this.formGroup1 = this.formBuilder.group({
      Email: [{ value: null, disabled: true }, []],
      NovaSenha: [null, Validators.required, this.passwordComplexity],
      ConfirmacaoNovaSenha: [null, Validators.required, this.comparePasswords],
    });
  }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(
        (params) => {
          if (params.token) {
            this.encodedToken = params.token;
            if (this.decodeToken()) {
              const request = new CheckAccountRequest(this.email || '', this.token || '');
              this.authService.checkAccount(request)
                .subscribe(
                  (response) => {
                    if (response.success) {
                      this.formGroup1.controls.Email.setValue(this.email);
                      this.formGroup1.controls.Email.disable();
                    } else {
                      this.router.navigate(['/']);
                    }
                  },
                  () => {
                    this.router.navigate(['/']);
                  }
                )
            } else {
              this.router.navigate(['/']);
            }
          } else {
            this.router.navigate(['/']);
          }
        },
        () => {
          this.router.navigate(['/']);
        });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.novaSenha1Element?.nativeElement.focus();
    }, 0);
  }

  reset() {
    this.formGroup1.reset();
    this.processing = false;
    setTimeout(() => {
      this.novaSenha1Element?.nativeElement.focus();
    }, 0);
  }

  retry() {
    this.formGroup1.reset();
    this.processing = false;
    setTimeout(() => {
      this.novaSenha1Element?.nativeElement.focus();
    }, 0);
  }

  decodeToken(): boolean {
    const decodedToken = atob(this.encodedToken || '');
    const tokenParts = decodedToken.split('&');
    tokenParts.forEach(part => {
      const propPart = part.split('=');
      if (propPart[0] === 'email') {
        this.email = propPart[1];
      }
      if (propPart[0] === 'token') {
        this.token = propPart[1];
      }
    });

    if (this.email && this.token) return true;
    return false;
  }

  onSubmit(post: any) {
    this.processing = true;
    const request = new RegisterPasswordRequest(this.email || '', this.token || '', post.NovaSenha);
    this.authService.registerPassword(request)
      .subscribe(
        (response) => {
          debugger;
          if (response.success) {
            this._alert.open(response, 'Senha registrada com sucesso');
            this.router.navigate(['/']);
          } else {
            this._alert.open(response);
            this.retry();
          }
        },
        (error) => {
          debugger;
          this._alert.open(error.error);
          this.retry();
        }
      );
  }

  getErrorNovaSenha() {
    return this.formGroup1.get('NovaSenha')?.hasError('required') ? 'Nova senha é obrigatória' :
      this.formGroup1.get('NovaSenha')?.hasError('notComplex') ? 'Nova senha não possui a complexidade exigida' : '';
  }

  getErrorConfirmacaoNovaSenha() {
    return this.formGroup1.get('ConfirmacaoNovaSenha')?.hasError('required') ? 'Confirmação de nova senha é obrigatória' :
      this.formGroup1.get('ConfirmacaoNovaSenha')?.hasError('notSame') ? 'Confirmação de nova senha não confere' : '';
  }

  clearConfirmacaoNovaSenha() {
    this.formGroup1.controls.ConfirmacaoNovaSenha.reset();
    this.formGroup1.controls.ConfirmacaoNovaSenha.markAsUntouched();
  }

}
