import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ManagementPanelService {
  apiUrl: string = environment.api;
  headers = new HttpHeaders().set('Content-Type', 'application/json');
  constructor(private http: HttpClient) { }

  public uploadImage(image: any): Observable<Response> {
    let API_URL = `${this.apiUrl}/carrosel/upload`;
    // const formData = new FormData();

    // formData.append('ImgUpload', image);
    // formData.append('ImgNome', 'card_01');

    let payload = {
      Id: 1,
      ImgUpload: image,
      ImgNome: 'teste2.jpg'
    }

    return this.http.post<Response>(API_URL, payload);
  }

  public uploadCard(image: any, pdf: any): Observable<Response> {
    let API_URL = `${this.apiUrl}/card/upload`;
    let payload = {
      Id: 1,
      ImgUpload: image[0],
      PdfUpload: pdf[0],
      ImgNome: 'teste2.jpg'
    }

    return this.http.post<Response>(API_URL, payload);
  }

}
