<div class="navbar">
  <button mat-mini-fab color="secondary" class="mat-elevation-z0" (click)="drawer.toggle()" *ngIf="loginDisplay">
    <mat-icon>menu</mat-icon>
  </button>

  <img src="assets/img/TelefonicaLogo.svg" alt="Telefônica" class="t-logo" [routerLink]="['/home']">

  <button class="login-button" mat-button color="primary" [routerLink]="['/login']"
    routerLinkActive="router-link-active" *ngIf="!loginDisplay">
    <mat-icon>person</mat-icon>
    Login
  </button>

  <button class="login-button" mat-button color="primary" [matMenuTriggerFor]="profileMenu" *ngIf="loginDisplay">
    {{user.name}}
    <mat-icon class="ml-2">account_circle</mat-icon>
  </button>
  <mat-menu #profileMenu="matMenu" xPosition="before">
    <button mat-menu-item [routerLink]="['/home/profile']" routerLinkActive="router-link-active"
      *ngIf="myProfileViewPermission">Meu Cadastro</button>
    <button mat-menu-item [routerLink]="['/home/change-password']"
    *ngIf="loginType === LOGIN_TYPES.LOCAL">Alterar Senha</button>
    <button mat-menu-item (click)="drawer.close(); logout();">Sair</button>
  </mat-menu>

</div>
<div>
  <mat-drawer-container class="outlet-container" hasBackdrop="true">
    <mat-drawer #drawer class="sidenav" mode="over">
      <div *ngIf="!mobile">
        <app-auto-open-menu (openEvent)="handleEvent($event, 'menu1')">
          <div trigger class="d-flex justify-content-start align-items-center m-item pr-2" [ngClass]="menu1">
            <div class="h-bar"></div>
            <mat-icon class="dot-i">fiber_manual_record</mat-icon>
            Solicitação de acompanhamento
          </div>
          <div content class="f-menu">
            <span mat-menu-item class="h-back mw-2" [routerLink]="['/home/list-follow-up-request']"
              (click)="drawer.close()">Gerenciamento de Acompanhamentos</span>
            <span mat-menu-item class="h-back" [routerLink]="['/home/manage-clients']"
              (click)="drawer.close()">Gerenciamento de Cliente</span>
          </div>
        </app-auto-open-menu>
        <app-auto-open-menu (openEvent)="handleEvent($event, 'menu2')" *ngIf="1 < 0">
          <div trigger class="d-flex justify-content-start align-items-center m-item pr-2" [ngClass]="menu2">
            <div class="h-bar"></div>
            <mat-icon class="dot-i">fiber_manual_record</mat-icon>
            Painéis de Controle
          </div>
          <div content>
            <span mat-menu-item class="h-back">Painel Gerencial</span>
          </div>
        </app-auto-open-menu>
        <app-auto-open-menu (openEvent)="handleEvent($event, 'menu3')" *ngIf="1 < 0">
          <div trigger class="d-flex justify-content-start align-items-center m-item pr-2" [ngClass]="menu3">
            <div class="h-bar"></div>
            <mat-icon class="dot-i">fiber_manual_record</mat-icon>
            Relatórios
          </div>
          <div content>
            <span mat-menu-item class="h-back">Intervenções</span>
          </div>
        </app-auto-open-menu>

        <!-- <button mat-menu-item [matMenuTriggerFor]="solicitacoes">
          <mat-icon class="c-white">fiber_manual_record</mat-icon>
          Solicitação de acompanhamento
        </button> -->

        <button mat-menu-item [routerLink]="['/home/visual-management-panel']"
          class="d-flex justify-content-start align-items-center p-0 m-item h-back" *ngIf="1 < 0"
          (click)="drawer.close()">
          <div class="h-bar"></div>
          <mat-icon class="dot-i mr-0">fiber_manual_record</mat-icon>
          Gerenciamento Visual
        </button>

        <button mat-menu-item [routerLink]="['/home/list-users']"
          class="d-flex justify-content-start align-items-center p-0 m-item h-back" *ngIf="usersCreatePermission"
          (click)="drawer.close()">
          <div class="h-bar"></div>
          <mat-icon class="dot-i mr-0">fiber_manual_record</mat-icon>
          Gestão de Usuários
        </button>

        <button mat-menu-item [routerLink]="['/home/retroactive-request']"
          class="d-flex justify-content-start align-items-center p-0 m-item h-back" *ngIf="retroactiveRequestPermission"
          (click)="drawer.close()">
          <div class="h-bar"></div>
          <mat-icon class="dot-i mr-0">fiber_manual_record</mat-icon>
          Processar Retroativos
        </button>
      </div>
      <div *ngIf="mobile">
        <mat-accordion>
          <mat-expansion-panel class="mat-elevation-z0">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="d-flex justify-content-start align-items-center m-item pr-2">
                  <div class="h-bar"></div>
                  <mat-icon class="dot-i">fiber_manual_record</mat-icon>
                  Solicitação de acompanhamento
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div>
              <div mat-menu-item class="h-back p-0 d-flex" [routerLink]="['/home/list-follow-up-request']"
                (click)="drawer.close()">
                <div class="h-bar mr-5"></div>
                <span>Gerenciamento de Acompanhamentos</span>
              </div>
              <div mat-menu-item class="h-back p-0 d-flex" [routerLink]="['/home/manage-clients']"
                (click)="drawer.close()">
                <div class="h-bar mr-5"></div>
                <span>Gerenciamento de Cliente</span>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
        <button mat-menu-item [routerLink]="['/home/create-user']"
          class="d-flex justify-content-start align-items-center px-29 m-item h-back" *ngIf="usersCreatePermission"
          (click)="drawer.close()">
          <mat-icon class="dot-i mr-0">fiber_manual_record</mat-icon>
          Cadastro de Usuários
        </button>
        <mat-menu #menuMob="matMenu">
        </mat-menu>
      </div>
    </mat-drawer>
    <div class="h-100">
      <router-outlet></router-outlet>
    </div>
  </mat-drawer-container>
</div>

<div class="footer">
  <div class="d-flex h-100 align-items-center justify-content-center">
    <img src="assets/img/AccentureLogo.svg" alt="Accenture" class="a-logo">
    <img src="assets/img/TelefonicaLogo.svg" alt="Telefônica" class="t-logo">

  </div>

</div>