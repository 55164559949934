import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Role } from './auth.model';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  constructor(
    private readonly authService: AuthService
  ) {
  }
  
  isRoleAllowed(allowedRoles:string[]) {
    const userRoles = this.authService.getRoles();
    if (!userRoles) return false;
     
    const matchingRoles = userRoles.filter((x: Role) => allowedRoles.includes(x.name));
    return matchingRoles.length > 0;
   }
}
