export class OTPRequest {
    email: string;
    password: string;

    constructor(email: string, password: string) {
        this.email = email;
        this.password = password;
    }
}

export class OTPResetPasswordRequest {
    email: string;

    constructor(email: string) {
        this.email = email;
    }
}

export class ResetPasswordRequest {
    email: string;
    token: string;
    password: string;

    constructor(email: string, token: string, password: string) {
        this.email = email;
        this.token = token;
        this.password = password;
    }
}

export class ChangePasswordRequest {
    PasswordOld: string;
    Password: string;

    constructor(oldPassowrd: string, newPassword: string) {
        this.PasswordOld = oldPassowrd;
        this.Password = newPassword;
    }
}

export class CheckAccountRequest {
    email: string;
    token: string;

    constructor(email: string, token: string) {
        this.email = email;
        this.token = token;
    }
}

export class RegisterPasswordRequest {
    email: string;
    token: string;
    password: string;

    constructor(email: string, token: string, password: string) {
        this.email = email;
        this.token = token;
        this.password = password;
    }
}

export class OTPConfirmation {
    email: string;
    token: string;

    constructor(email: string, token: string) {
        this.email = email;
        this.token = token;
    }
}

export class OTPPasswordResetConfirmation {
    email: string;
    token: string;
    password: string;

    constructor(email: string, token: string, password: string) {
        this.email = email;
        this.token = token;
        this.password = password;
    }
}


export class Role {
    id: number;
    name: string;

    constructor(id: number, name: string) {
        this.id = id;
        this.name = name;
    }
}