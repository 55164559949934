import { LocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil, switchMap } from 'rxjs/operators';
import { AuthService } from 'src/auth/auth.service';
import { NavigationService } from 'src/auth/navigation.service';
import { RoleService } from 'src/auth/role.service';
import { AlertService } from 'src/shared/components/alert/alert.service';
import { Roles } from 'src/shared/models/roles';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'portal-clientes-webapp';
  mobile = false;
  loginDisplay = false;
  isIframe = false;
  user: any;
  showFiller = false;
  menu1 = '';
  menu2 = '';
  menu3 = '';
  menu4 = '';
  myProfileViewPermission = false;
  groupsAndClientsManagePermission = false;
  disabled = true;
  retroactiveRequestPermission = false;
  usersCreatePermission = false;
  loginType: string | null = this.authService.LOGIN_TYPES.UNKNOWN;
  LOGIN_TYPES = this.authService.LOGIN_TYPES;
  private readonly _destroying$ = new Subject<void>();

  constructor(
    private readonly authService: AuthService,
    private readonly roleService: RoleService,
    private readonly msalService: MsalService,
    private readonly msalBroadcastService: MsalBroadcastService,
    private readonly router: Router,
    private readonly _alert: AlertService,
    private readonly location: LocationStrategy,
    private readonly navigationService: NavigationService,
  ) {
    // check if back or forward button is pressed.
    this.location.onPopState(() => {
      // set isBackButtonClicked to true.
      this.navigationService.setBackClicked();
      return false;
    });
  };

  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener;
    if (!this.isIframe) {
      this.msalService.handleRedirectObservable()
        .pipe(
          switchMap(() => this.authService.msalHandleRedirect())
        )
        .subscribe((status) => {
          if (status) {
            this.setLoginDisplay();
            this.router.navigate(['/home']);
          }
        });

      // this.msalBroadcastService.msalSubject$
      //   .pipe(
      //     filter(
      //       (msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS
      //         || msg.eventType === EventType.SSO_SILENT_SUCCESS)
      //   ).subscribe((result: EventMessage) => {
      //     const payload = result.payload as AuthenticationResult;
      //     this.msalService.instance.setActiveAccount(payload.account);
      //   });

      this.setLoginDisplay();

      if (window.screen.width === 360) { // 768px portrait
        this.mobile = true;
      }
    }
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.isTokenPresent();
    this.user = this.authService.getUser();
    this.setPermissions();
  }

  handleEvent(value: any, menu: string) {
    if (menu === 'menu1') { this.menu1 = value }
    else if (menu === 'menu2') { this.menu2 = value }
    else if (menu === 'menu3') { this.menu3 = value }
    else if (menu === 'menu4') { this.menu4 = value }
  }

  logout() {
    this.authService.logout().subscribe(
      () => {
        this.ngOnInit();
      }
    );
  }

  setPermissions() {
    this.myProfileViewPermission = this.roleService.isRoleAllowed([Roles.myUserView]);
    this.groupsAndClientsManagePermission = this.roleService.isRoleAllowed([Roles.groupsAndClientsManage]);
    this.retroactiveRequestPermission = this.roleService.isRoleAllowed([Roles.admin]);
    this.usersCreatePermission = this.roleService.isRoleAllowed([Roles.usersCreate]);
    this.loginType = this.authService.getLoginType();
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }


 
}
