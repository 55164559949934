import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  apiUrl: string = environment.api;
  headers = new HttpHeaders().set('Content-Type', 'application/json');

  constructor(private http: HttpClient) { }

  getCards(): Observable<any> {
    let API_URL = `${this.apiUrl}/card`;
    return this.http.post(API_URL, {})
      .pipe(
        catchError( err => {throw err})
      )
  }
}
