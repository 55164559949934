import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthService, IS_AUTH_API } from 'src/auth/auth.service';

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return from(this.handle(request, next));
  }

  handle(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.context.get(IS_AUTH_API)) return next.handle(req);

    return this.authService.verifyToken()
      .pipe(
        switchMap((response): Observable<HttpEvent<any>> => {
          if (response) {
            const headers = {
              Authorization: `Bearer ${this.authService.getToken()}`
            } as any;

            const authReq = req.clone({
              setHeaders: headers
            })

            return next.handle(authReq)
              .pipe(
                catchError((err) => {
                  if (err && err.status && ((err.status === HttpStatusCode.Unauthorized) || (err.status === HttpStatusCode.Forbidden))) {
                    return this.authService.logout().pipe(
                      switchMap(() => throwError(err))
                    )
                  }
                  return throwError(err)
                })
              );
          }
          return this.authService.logout()
            .pipe(
              switchMap(() => { return throwError(new Error('Usuário não autenticado')) })
            );
        })
      );
  }
}
