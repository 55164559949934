import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutoOpenMenuComponent } from './auto-open-menu/auto-open-menu.component';
import { MaterialModule } from '../material/material.module';
import { AlertComponent } from './alert/alert.component';
import { SpinnerOverlayComponent } from './spinner-overlay/spinner-overlay.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ImageCropperComponent } from './image-cropper/image-cropper.component';

@NgModule({
  declarations: [
    AutoOpenMenuComponent,
    AlertComponent,
    SpinnerOverlayComponent,
    ConfirmationDialogComponent,
    ImageCropperComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    ImageCropperModule,

  ],
  exports: [
    AutoOpenMenuComponent,
    AlertComponent,
    SpinnerOverlayComponent
  ],
  entryComponents: [
    ConfirmationDialogComponent
  ]
})
export class SharedComponentsModule { }
