<div class="d-flex align-items-center" *ngIf="success === true">
    <mat-icon class="mr-2">check_circle_outline</mat-icon>
    <span>{{ successMessage }}</span>
</div>

<div class="d-flex flex-column" *ngIf="success != true">
    <div *ngFor="let error of errors" class="d-flex align-items-center">
        <mat-icon class="mr-2">cancel_outline</mat-icon>
        <span>{{ error }}</span>
    </div>
</div>

<div class="d-flex align-items-center" *ngIf="success != true && errors == undefined">
    <mat-icon class="mr-2">cancel_outline</mat-icon>
    <span>{{ error }}</span>
</div>