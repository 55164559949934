import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { NavigationService } from './navigation.service';

@Injectable({
  providedIn: 'root'
})
export class NavigationGuard implements CanDeactivate<any> {
  constructor(private navigationService: NavigationService) { }
  canDeactivate(component: any) {
    if (this.navigationService.getBackClicked()) {
      history.pushState(null, null as any, location.href);
      return false;
    }
    return true;
  }

}
