import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from "@angular/router";
import { MsalService } from "@azure/msal-angular";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { AlertService } from "src/shared/components/alert/alert.service";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: 'root'
})

export class RoleGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private _alert: AlertService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const allowedRoles = next.data["roles"];
    if (!allowedRoles) return true;

    const userRoles = this.authService.getRoles();
    const matchingRoles = userRoles.filter((x: any) => allowedRoles.includes(x.name));
    //  if (environment.disableRolesAuthorization) {
    //   return true;
    //  }
    if (matchingRoles.length < 1) {
      this._alert.openCustomError(['Você não tem permissão para acessar esta página.']);
    }
    return matchingRoles.length > 0;
  }
}