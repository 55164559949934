<div class="p-5 container-i">
    <input type="file" (change)="fileChangeEvent($event)" #someInput/>
    <div style="display: block; width: 210px">
        <image-cropper
            [imageChangedEvent]="imageChangedEvent"
            [maintainAspectRatio]="true"
            [aspectRatio]="4 / 3"
            format="png"
            (imageCropped)="imageCropped($event)"
            (imageLoaded)="imageLoaded()"
            (cropperReady)="cropperReady()"
            (loadImageFailed)="loadImageFailed()"
            [resizeToWidth]="200"
        ></image-cropper>
    </div>
    <div style="padding: 5px;">
        <img [src]="croppedImage" />
    </div>
    <div class="col-12 d-flex justify-content-between mt-3">
        <div>
            <button mat-raised-button color="disabled" class="button" type="button" (click)="openConfirmationDialog()">
                Cancelar
            </button>
        </div>           
            <button class="button" mat-raised-button color="primary" matTooltip="Salvar" (click)="handleService()">Salvar</button>
        </div>
</div>