<div class="p-5">
    <h2>Registrar nova senha</h2>
    <mat-card class="p-card mat-elevation-z0">
        <div class="container">
            <form [formGroup]="formGroup1" (ngSubmit)="onSubmit(formGroup1.getRawValue())" class="form">
                <div class="row">
                    <mat-form-field class="form-element col-12 col-lg-6">
                        <mat-label>E-mail</mat-label>
                        <input matInput placeholder="Email" formControlName="Email" readonly>
                    </mat-form-field>
                </div>
                <div class="row">
                    <mat-form-field class="form-element col-12 col-lg-6">
                        <mat-label>Nova senha</mat-label>
                        <input matInput placeholder="NovaSenha" #novaSenha1 formControlName="NovaSenha" maxlength="30"
                            type="password" (change)="this.clearConfirmacaoNovaSenha()">
                        <mat-error
                            *ngIf="!formGroup1.controls['NovaSenha'].valid && formGroup1.controls['NovaSenha'].touched">
                            {{ getErrorNovaSenha() }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="row">
                    <mat-form-field class="form-element col-12 col-lg-6">
                        <mat-label>Confirmação de nova senha</mat-label>
                        <input matInput placeholder="ConfirmacaoNovaSenha" formControlName="ConfirmacaoNovaSenha"
                            maxlength="30" type="password">
                        <mat-error
                            *ngIf="!formGroup1.controls['ConfirmacaoNovaSenha'].valid && formGroup1.controls['ConfirmacaoNovaSenha'].touched">
                            {{ getErrorConfirmacaoNovaSenha() }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="row">
                    <div
                        class="form-element col-12 col-lg-3 d-flex justify-content-center justify-content-lg-start mt-3">
                        <button mat-raised-button color="disabled" class="button" type="button" [routerLink]="['/home']"
                            routerLinkActive="router-link-active" [disabled]="processing">Cancelar
                        </button>
                    </div>
                    <div
                        class="form-element col-12 col-lg-3 d-flex flex-lg-row-reverse justify-content-center justify-content-lg-start mt-3">
                        <button mat-raised-button color="primary" type="submit" class="button"
                            [disabled]="!formGroup1.valid || processing">Registrar
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </mat-card>
</div>