import {Component, Inject, OnInit} from '@angular/core';
import {MAT_SNACK_BAR_DATA} from '@angular/material/snack-bar';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {
  successMessage = this.data.successMessage;
  errors = this.data.response?.errors;
  error = this.data.response?.message;
  success = this.data.response?.success;
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any
    ) { }

  ngOnInit(): void {
  }

}
