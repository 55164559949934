import { HttpClient, HttpContext, HttpContextToken, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult } from '@azure/msal-browser';
import jwt_decode from 'jwt-decode';
import { Observable, of, from } from 'rxjs';
import { catchError, map, tap, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AjaxResponse } from 'src/shared/models/response';
import { ChangePasswordRequest, CheckAccountRequest, OTPConfirmation, OTPRequest, OTPResetPasswordRequest, RegisterPasswordRequest, ResetPasswordRequest, Role } from './auth.model';

export const IS_AUTH_API = new HttpContextToken<boolean>(() => false);

export const LOGIN_TYPES = {
  UNKNOWN: 'unknown',
  ACN_ESO: 'acn_eso',
  LOCAL: 'local'
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly apiUrl: string = environment.api;
  private readonly headers = new HttpHeaders().set('Content-Type', 'application/json');

  public LOGIN_TYPES = LOGIN_TYPES;
  constructor(
    private http: HttpClient,
    private readonly router: Router,
    private readonly msalService: MsalService) { }

  /**
   * @name requestOTP
   * @anonymous
   * @param otpRequest 
   * @returns Observable<AjaxResponse<any>>
   */
  requestOTP(otpRequest: OTPRequest): Observable<AjaxResponse<any>> {
    const apiMethod = `${this.apiUrl}/account/authentication`;
    const headers = this.headers;
    const context = new HttpContext().set(IS_AUTH_API, true);
    return this.http.post<AjaxResponse<any>>(apiMethod, otpRequest, { headers, context })
      .pipe(
        catchError(err => { throw err })
      )
  }

  /**
   * @name authenticateByOTP
   * @anonymous
   * @param otpConfirmation
   * @returns Observable<AjaxResponse<any>>
   */
  authenticateByOTP(otpConfirmation: OTPConfirmation): Observable<AjaxResponse<any>> {
    const apiMethod = `${this.apiUrl}/account/tokenvalidation`;
    const headers = this.headers;
    const context = new HttpContext().set(IS_AUTH_API, true);
    return this.http.post<AjaxResponse<any>>(apiMethod, otpConfirmation, { headers, context })
      .pipe(
        catchError(err => { throw err })
      )
  }

  /**
   * @name requestOTPPasswordRequest
   * @anonymous
   * @param otpResetPasswordRequest
   * @returns Observable<AjaxResponse<any>>
   */
  requestOTPPasswordRequest(otpResetPasswordRequest: OTPResetPasswordRequest): Observable<AjaxResponse<any>> {
    const apiMethod = `${this.apiUrl}/account/forgotpassword`;
    const headers = this.headers;
    const context = new HttpContext().set(IS_AUTH_API, true);
    return this.http.post<AjaxResponse<any>>(apiMethod, otpResetPasswordRequest, { headers, context })
      .pipe(
        catchError(err => { throw err })
      )
  }

  /**
   * @name resetPassword
   * @anonymous
   * @param resetPasswordRequest
   * @returns Observable<AjaxResponse<any>>
   */
  resetPassword(resetPasswordRequest: ResetPasswordRequest): Observable<AjaxResponse<any>> {
    const apiMethod = `${this.apiUrl}/account/resetpassword`;
    const headers = this.headers;
    const context = new HttpContext().set(IS_AUTH_API, true);
    return this.http.post<AjaxResponse<any>>(apiMethod, resetPasswordRequest, { headers, context })
      .pipe(
        catchError(err => { throw err })
      )
  }

  /**
   * @name refreshRoles
   * @returns Observable<boolean>
   */
  refreshRoles(): Observable<boolean> {
    const headers = this.headers;
    const apiMethod = `${this.apiUrl}/usuario/roles`;
    return this.http.get<AjaxResponse<any>>(apiMethod, { headers })
      .pipe(
        map((response) => {
          if (response.data) {
            this.setRoles(response.data);
            return true
          }
          return false;
        })
      );
  }


  /**
   * @name msalLogin
   * @msal
   * @param email
   * @returns Observable<boolean>
   */
  msalLogin(email: string): Observable<boolean> {
    return this.msalService.ssoSilent({
      scopes: [environment.getTokenScope],
      loginHint: email
    }).pipe(
      map((response: AuthenticationResult) => {
        if (response.idToken) {
          this.setToken(response.idToken);
          return true;
        }
        throw new Error('Token inválido');
      }),
      catchError(() => {
        return this.msalService.loginRedirect({
          scopes: [environment.getTokenScope],
          loginHint: email
        }).pipe(
          map(() => true)
        )
      }),
    );
  }

  msalHandleRedirect(): Observable<boolean> {

    return this.setTokenByMSAL()
      .pipe(
        switchMap((resultado) => {
         
          if (resultado) {
            return this.refreshRoles()
              .pipe(
                switchMap(
                  (resultado) => {
                  
                    if (!resultado) {
                      return this.logout()
                        .pipe(
                          map(() => false)
                        );
                    }
                    return of(true);
                  }
                ),
                catchError(() => {
                  return this.logout()
                    .pipe(
                      map(() => false)
                    );
                })
              );
          }
          return of(false);
        }),
      );
  }

  /**
   * @name refreshToken
   * @msal
   * @returns Observable<boolean>
   */
  refreshToken(): Observable<boolean> {
    return from(
      this.msalService.ssoSilent({
        scopes: [environment.getTokenScope],
      }))
      .pipe(
        map((response): boolean => {
          if (response.idToken) {
            this.setToken(response.idToken);
            return true;
          }
          return false;
        }),
        catchError(() => of(false))
      );
  }

  /**
   * @name reloadApp
   * @returns Observable<void>
   */
  reloadApp() {
    return from(this.router.navigateByUrl('/empty', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/']).then(() => {
      })
    })).pipe(map(() => false));
  }

  /**
   * @name logout
   * @returns Observable<boolean>
   */
  logout(): Observable<boolean> {
    let obs = of();
    switch (this.getLoginType()) {
      case LOGIN_TYPES.LOCAL:
        obs = this.localLogout();
        break;

      case LOGIN_TYPES.ACN_ESO:
        obs = this.esoLogout();
        break;

      default:
        obs = of(true);
    }
    return obs.pipe(switchMap(() => this.reloadApp()));
  }

  /**
   * @name localLogout
   * @returns Observable<boolean>
   */
  localLogout(): Observable<boolean> {
    localStorage.clear();
    return from(this.router.navigate(['/']).then(() => {
      this.router.navigate([], {
        skipLocationChange: true,
        queryParamsHandling: 'merge' //== if you need to keep queryParams
      })
    })).pipe(
      map(() => true)
    );
  }

  /**
   * @name esoLogout
   * @msal
   * @returns Observable<boolean>
   */
  esoLogout(): Observable<boolean> {
    localStorage.clear();
    const currentAccount = this.msalService.instance.getAllAccounts()[0];
    return from(this.msalService.instance.logoutRedirect({
      account: currentAccount
    })).pipe(
      map(() => true)
    );
  }

  /**
   * @name isAuthenticated
   * @returns Observable<boolean>
   */
  isAuthenticated(): Observable<boolean> {
    if (!this.isTokenPresent()) return of(false);
    return this.verifyToken();
  }

  /**
   * @name isTokenPresent
   * @returns boolean
   */
  isTokenPresent(): boolean {
    return (this.getToken()?.length || 0) > 0;
  }

  /**
   * @name verifyToken
   * @returns Observable<boolean>
   */
  verifyToken(): Observable<boolean> {
    if (this.isTokenPresent()) {
      const token = this.getToken() || '';
      const tokenParts = token.split('.');
      if (tokenParts.length > 1) {
        const decode = JSON.parse(atob(tokenParts[1]));
        if (decode.exp * 1000 >= new Date().getTime()) {
          return of(true);
        } else {
          if (this.getLoginType() == LOGIN_TYPES.ACN_ESO)
            return this.refreshToken()
              .pipe(
                tap((response) => {
                  if (!response)
                    this.logout();
                })
              );
        }
      }
    }
    return of(false);
  }

  /**
   * @name getRoles
   * @returns Role[]
   */
  getRoles(): Role[] {
    const strRoles = localStorage.getItem('roles');
    return strRoles ? JSON.parse(strRoles) : [];
  }

  /**
   * @name setRoles
   * @param roles
   */
  setRoles(roles: Role[]): void {
    localStorage.setItem('roles', JSON.stringify(roles));
  }

  /**
   * @name getUser
   * @param object
   */
  getUser() {
    if (this.isTokenPresent()) {
      const token = this.getToken() || '';
      const decoded = jwt_decode(token) as any;
      return {
        unique_name: decoded.unique_name || '',
        email: decoded.email || '',
        preferred_username: decoded.preferred_username || '',
        given_name: decoded.given_name || '',
        name: decoded.name || '',
        family_name: decoded.family_name || '',
        oid: decoded.oid || null,
        tid: decoded.tid || null,
        id: decoded.id || null,
      };
    }
    return null;
  }

  /**
   * @name setToken
   * @param token
   */
  setToken(token: string) {
    localStorage.setItem('token', token);
  }
  /**
   * @name setTokenByMSAL
   * @msal
   * @returns Observable<boolean>
   */
  setTokenByMSAL(): Observable<boolean> {
  

    if (!this.msalService.instance.getActiveAccount()) {
      const accounts = this.msalService.instance.getAllAccounts();
      if (accounts.length > 0) {
        const user = accounts[0];
        this.msalService.instance.setActiveAccount(user);
      }
    }

    if (this.msalService.instance.getActiveAccount()) {
      return this.msalService
        .acquireTokenSilent({ scopes: [environment.getTokenScope] })
        .pipe(
          map((authResult) => {
           
            if (authResult.idToken) {
              this.setToken(authResult.idToken);
              return true;
            }
            return false;
          }),
          catchError(error => {
         
            return of(false)
          }
          )
        );
    }
    return of(false);
  }

  /**
   * @name setToken
   * @returns string | null
   */
  getToken(): string | null {
    return localStorage.getItem('token');
  }

  /**
   * @name setLoginType
   * @param loginType
   */
  setLoginType(loginType: string) {
    return localStorage.setItem('login_type', loginType);
  }

  /**
   * @name getLoginType
   * @returns string | null
   */
  getLoginType(): string | null {
    return localStorage.getItem('login_type');
  }

  /**
   * @name changePassword
   * @param changePasswordRequest
   * @returns Observable<AjaxResponse<any>>
   */
  changePassword(changePasswordRequest: ChangePasswordRequest): Observable<AjaxResponse<any>> {
    const apiMethod = `${this.apiUrl}/account/changepassword`;
    const headers = this.headers;
    return this.http.post<AjaxResponse<any>>(apiMethod, changePasswordRequest, { headers })
      .pipe(
        catchError(err => { throw err })
      )
  }

  checkAccount(checkAccountRequest: CheckAccountRequest): Observable<AjaxResponse<any>> {
    const apiMethod = `${this.apiUrl}/account/checkaccount`;
    const headers = this.headers;
    const context = new HttpContext().set(IS_AUTH_API, true);
    return this.http.post<AjaxResponse<any>>(apiMethod, checkAccountRequest, { headers, context })
      .pipe(
        catchError(err => { throw err })
      )
  }

  registerPassword(registerPasswordRequest: RegisterPasswordRequest): Observable<AjaxResponse<any>> {
    const apiMethod = `${this.apiUrl}/account/registerpassword`;
    const headers = this.headers;
    const context = new HttpContext().set(IS_AUTH_API, true);
    return this.http.post<AjaxResponse<any>>(apiMethod, registerPasswordRequest, { headers, context })
      .pipe(
        catchError(err => { throw err })
      )
  }

  validatePasswordComplexity(password: string): boolean {
    const regexGeneral = /^((?!.*\s)(?=.*([a-z]|[A-Z]|\p{P}|\p{S}|\d))).{8,30}$/;
    const regexAtLeast3UpperLetters = /^(?=((?:.*?)[A-Z]){3}).{3,}/;
    const regexAtLeast3LowerLetters = /^(?=((?:.*?)[a-z]){3}).{3,}/;
    const regexAtLeast3SpecialChars = /^(?=((?:.*?)[\!\@\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-]){3}).{3,}/;
    const regexAtLeast3Digits = /^(?=((?:.*?)\d){3}).{3,}/;
    const regexUpperLetterPresent = /^(?=((?:.*?)[A-Z]))/;
    const regexLowerLetterPresent = /^(?=((?:.*?)[a-z]))/;
    const regexSpecialCharPresent = /^(?=((?:.*?)[\!\@\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-]))/
    const regexDigitPresent = /^(?=((?:.*?)\d))/;

    if (!regexGeneral.test(password)) return false;

    if (!regexAtLeast3UpperLetters.test(password) &&
      !regexAtLeast3LowerLetters.test(password) &&
      !regexAtLeast3SpecialChars.test(password) &&
      !regexAtLeast3Digits.test(password)) return false;

    if ((regexUpperLetterPresent.test(password) ? 1 : 0) +
      (regexLowerLetterPresent.test(password) ? 1 : 0) +
      (regexSpecialCharPresent.test(password) ? 1 : 0) +
      (regexDigitPresent.test(password) ? 1 : 0) < 3) return false;

    return true;
  }


}
