import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { ManagementPanelService } from 'src/app/features/management-panel/management-panel.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
@Component({
  selector: 'app-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss']
})
export class ImageCropperComponent implements OnInit {
  imageChangedEvent: any = '';
  croppedImage: any = '';
  showCropper = false;
  fileId: any;
  serviceId: number = 0;
  type: ('carroulssel' | 'link' | null) = 'carroulssel'; 

  constructor(
    private managementPanelService: ManagementPanelService,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
  }

  fileChangeEvent(event: any): void {
      this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
      this.croppedImage = event.base64;
  }
  imageLoaded() {
      // show cropper
      this.showCropper = true;
  }
  cropperReady() {
      // cropper ready
  }
  loadImageFailed() {
      // show message
  }

  handleService() {
      console.log('oi')
      if (this.type === 'carroulssel') {
        this.managementPanelService.uploadImage(this.croppedImage).subscribe(
            (res) => {
            
            },
            (err) => {
            
            });
    } else if (this.type === 'link') {

      }
  }

  openConfirmationDialog() {
    let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false
    });
    dialogRef.componentInstance.confirmMessage = "Suas alterações serão perdidas, deseja continuar?";
    dialogRef.componentInstance.confirmTitle = "Você tem certeza?";
    dialogRef.componentInstance.confirmButton = "Confirmar";
    dialogRef.componentInstance.cancelButton = "Cancelar";
    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.dialog.closeAll();
      }
    });
  }

}
