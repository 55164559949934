import { Component, OnInit, ViewChild, AfterViewInit, ElementRef, AfterContentChecked, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs/internal/Subscription';
import { AppComponent } from 'src/app/app.component';
import { OTPConfirmation, OTPRequest } from 'src/auth/auth.model';
import { AuthService, LOGIN_TYPES } from 'src/auth/auth.service';
import { AlertService } from 'src/shared/components/alert/alert.service';
import { SpinnerOverlayService } from 'src/shared/components/spinner-overlay/spinner-overlay.service';

export const STATES = {
  PHASE1: 0,
  PHASE2: 1,
  PHASE3: 2
};

const LOCAL_DOMAINS = ['telefonica.com', 'avanade.com'];
const ACN_ESO_DOMAINS = ['accenture.com', 'ds.dev.accenture.com'];
const ALLOWED_DOMAINS = [...LOCAL_DOMAINS, ...ACN_ESO_DOMAINS];

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit, AfterContentChecked {
  @ViewChild('email1') email1Element: ElementRef | undefined;
  @ViewChild('senha2') senha2Element: ElementRef | undefined;
  @ViewChild('token3') token3Element: ElementRef | undefined;

  public readonly LOGIN_TYPES = LOGIN_TYPES;
  public readonly STATES = STATES;



  private loginType = LOGIN_TYPES.UNKNOWN;
  private state = STATES.PHASE1;
  formGroup1: FormGroup;
  formGroup2: FormGroup;
  formGroup3: FormGroup;

  spinnerSubscription: Subscription | null = null;

  processing = false;

  /**
  *
  */
  constructor(
    private readonly router: Router,
    private readonly formBuilder: FormBuilder,
    private readonly authService: AuthService,
    private readonly _alert: AlertService,
    private readonly appComponent: AppComponent,
    private readonly spinnerOverlayService: SpinnerOverlayService,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {


    const allowedEMailDomains = ALLOWED_DOMAINS.map((domain) => { return '.+@' + domain.replace(/\./g, '\\.') }).join('|');
    this.formGroup1 = this.formBuilder.group({
      Email: [null, [Validators.required, Validators.email, Validators.pattern(allowedEMailDomains)]],
    });

    this.formGroup2 = this.formBuilder.group({
      Email: [{ value: null, disabled: true }, []],
      Senha: [null, Validators.required],
    });

    this.formGroup3 = this.formBuilder.group({
      Email: [{ value: null, disabled: true }, []],
      Senha: [{ value: null, disabled: true }, []],
      Token: [null, Validators.required],
    });
  }


  ngOnInit(): void {
    this.changeDetectorRef.detach();
    this.authService.isAuthenticated()
      .subscribe((result) => {
        if (result) {
          this.router.navigate(['/home']);
        }
      });
  }

  ngAfterViewInit(): void {
    this.email1Element?.nativeElement.focus();
  }

  ngAfterContentChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  get LoginType() {
    return this.loginType;
  }

  get State() {
    return this.state;
  }

  reset() {
    this.state = STATES.PHASE1;
    this.loginType = LOGIN_TYPES.UNKNOWN;
    this.formGroup1.reset();
    this.formGroup2.reset();
    this.formGroup3.reset();
    this.spinnerSubscription?.unsubscribe();
    if (this.spinnerSubscription) this.spinnerSubscription = null;
    setTimeout(() => {
      this.email1Element?.nativeElement.focus();
    }, 0);
    this.processing = false;
  }

  getErrorEmail() {
    return this.formGroup1?.get('Email')?.hasError('required') ? 'Campo obrigatório' :
      this.formGroup1?.get('Email')?.hasError('pattern') ? 'E-mail inválido' : '';
  }

  getErrorSenha() {
    return this.formGroup2?.get('Senha')?.hasError('required') ? 'Campo obrigatório' : '';
  }
  getErrorToken() {
    return this.formGroup3?.get('Token')?.hasError('required') ? 'Campo obrigatório' : '';
  }

  isLocalAuthentication(email: string) {
    return LOCAL_DOMAINS.some(domain => email.endsWith(domain));
  }

  isAccentureESOAuthentication(email: string) {
    return ACN_ESO_DOMAINS.some(domain => email.endsWith(domain));
  }

  private setLoginType(loginType: string) {
    this.loginType = loginType;
    this.authService.setLoginType(loginType);
  }

  onSubmitPhase1(post: any) {
    this.processing = true;
    if (this.isLocalAuthentication(post.Email)) {
      this.setLoginType(LOGIN_TYPES.LOCAL);
      this.state = STATES.PHASE2;
      this.formGroup2.controls.Email.setValue(post.Email);
      setTimeout(() => {
        this.senha2Element?.nativeElement.focus();
        this.processing = false;
      }, 0);
    } else if (this.isAccentureESOAuthentication(post.Email)) {
      this.setLoginType(LOGIN_TYPES.ACN_ESO);
      this.spinnerSubscription = this.spinnerOverlayService.spinner$.subscribe();
      this.authService.msalLogin(post.Email).subscribe(
        (response: any) => {
          if (response) {
            this.refreshRoles();
          }
        }
      )
    } else {
      this.loginType = LOGIN_TYPES.UNKNOWN;
      this._alert.openCustomError(['Não foi possível identificar o tipo de autenticação.']);
      this.reset();
    }
  }

  onSubmitPhase2(post: any) {
    this.processing = true;
    var request = new OTPRequest(post.Email, post.Senha);
    this.authService.requestOTP(request).subscribe(
      (response: any) => {
        if (response.success === true) {
          this.state = STATES.PHASE3;
          this.formGroup3.setValue({ Email: post.Email, Senha: post.Senha, Token: null });
          setTimeout(() => {
            this.token3Element?.nativeElement.focus();
            this.processing = false;
          }, 0);
          this._alert.open(response, `Token de acesso enviado com sucesso para o e-mail "${post.Email}".`);
        } else {
          this._alert.openCustomError(['Erro ao solicitar o token de acesso.']);
          console.log('Erro ao solicitar o token de acesso.');
          this.reset();
        }
      },
      err => {
        this._alert.openCustomError(['Erro ao solicitar o token de acesso.']);
        console.log('Erro ao solicitar o token de acesso.');
        this.reset();
      }
    );
  }

  onSubmitPhase3(post: any) {
    this.processing = true;
    var request = new OTPConfirmation(post.Email, post.Token);
    this.authService.authenticateByOTP(request).subscribe(
      (response: any) => {
        if (response.success === true) {
          //TODO: Validate the token
          this.authService.setToken(response.data);
          this.refreshRoles();
        } else {
          this._alert.openCustomError(['Erro ao confirmar o token de acesso.']);
          console.log('Erro ao confirmar o token de acesso.');
          this.reset();
        }
      }, err => {
        this._alert.openCustomError(['Erro ao confirmar o token de acesso.']);
        console.log('Erro ao confirmar o token de acesso.');
        this.reset();
      }
    );
  }

  refreshRoles() {
    this.authService.refreshRoles().subscribe(
      () => {
        this.router.navigate(['home']);
        this.appComponent.ngOnInit();
        this.spinnerSubscription?.unsubscribe();
        this.processing = false;
      },
      () => {
        this._alert.openCustomError(['Erro ao confirmar o token de acesso.']);
        console.log('Erro ao confirmar o token de acesso.');
        this.reset();
      }
    );
  }

  cancelLogin() {
    this.reset();
  }

  recoverPassword() {
    this.router.navigate(['recover-password'], { state: { email: this.formGroup1.controls.Email.value } });
  }
}
