import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private backClicked: boolean;
  constructor() {
    this.backClicked = false;
  }

  getBackClicked() {
    const value = this.backClicked;
    if (value) this.backClicked = false;
    return value;
  }

  setBackClicked() {
    this.backClicked = true;
  }
}
