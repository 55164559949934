import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertComponent } from './alert.component';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(private _snackBar: MatSnackBar) { }

  open( data: any, successMessage?: string) {
    this._snackBar.openFromComponent(AlertComponent, {
      panelClass: data?.success == true ? 'success-alert' : 'error-alert',
      horizontalPosition: 'center',
      verticalPosition: 'top',
      duration: 5000,
      data: {
        successMessage: successMessage,
        response: data
      }
    })
  }

  openCustomError(errorMessages: string[]) {
    this._snackBar.openFromComponent(AlertComponent, {
      panelClass: 'error-alert',
      horizontalPosition: 'center',
      verticalPosition: 'top',
      duration: 5000,
      data: {
        successMessage: '',
        response: {
          success: false,
          errors: errorMessages
        }
      }
    })
  }


}
