import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, of, Subscription } from 'rxjs';
import { OTPResetPasswordRequest, ResetPasswordRequest } from 'src/auth/auth.model';
import { AuthService } from 'src/auth/auth.service';
import { AlertService } from 'src/shared/components/alert/alert.service';

export const STATES = {
  PHASE0: 0,
  PHASE1: 1,
  PHASE2: 2,

};
@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.scss']
})
export class RecoverPasswordComponent implements OnInit {
  @ViewChild('novaSenha2') novaSenha2Element: ElementRef | undefined;

  private state = STATES.PHASE0;
  formGroup1: FormGroup;
  formGroup2: FormGroup;

  public readonly STATES = STATES;

  email = '';

  spinnerSubscription: Subscription | null = null;

  processing = false;

  passwordComplexity = (control: AbstractControl): Observable<ValidationErrors | null> => {
    return of(this.authService.validatePasswordComplexity(control.value) ? null : { notComplex: true })

  }
  comparePasswords = (): Observable<ValidationErrors | null> => {
    return of(this.formGroup2.controls.NovaSenha.value === this.formGroup2.controls.ConfirmacaoNovaSenha.value ? null : { notSame: true });
  }

  constructor(
    private readonly router: Router,
    private readonly formBuilder: FormBuilder,
    private readonly authService: AuthService,
    private readonly _alert: AlertService,) {
    this.email = this.router.getCurrentNavigation()?.extras?.state?.email;

    this.formGroup1 = this.formBuilder.group({
      Email: [{ value: null, disabled: true }, []],
    });
    this.formGroup2 = this.formBuilder.group({
      Email: [{ value: null, disabled: true }, []],
      Token: [null, Validators.required],
      NovaSenha: [null, Validators.required, this.passwordComplexity],
      ConfirmacaoNovaSenha: [null, Validators.required, this.comparePasswords],
    });
  }

  reset() {
    this.state = STATES.PHASE0;
    this.email = '';
    this.formGroup1.reset();
    this.formGroup2.reset();
    this.spinnerSubscription?.unsubscribe();
    if (this.spinnerSubscription) this.spinnerSubscription = null;
    this.processing = false;
  }

  retry() {
    this.formGroup2.controls.NovaSenha.reset();
    this.formGroup2.controls.ConfirmacaoNovaSenha.reset();
    setTimeout(() => {
      this.novaSenha2Element?.nativeElement.focus();
    }, 0);
    this.spinnerSubscription?.unsubscribe();
    if (this.spinnerSubscription) this.spinnerSubscription = null;
    this.processing = false;
  }

  get State() {
    return this.state;
  }

  ngOnInit(): void {
    this.processing = true;
    if (this.email) {
      this.formGroup1.setValue({ Email: this.email });
      this.state = this.STATES.PHASE1;
      var request = new OTPResetPasswordRequest(this.email);
      this.authService.requestOTPPasswordRequest(request)
        .subscribe(
          (response: any) => {
            if (response.success === true) {
              this.state = STATES.PHASE2;
              this.formGroup2.setValue({ Email: this.email, Token: null, NovaSenha: null, ConfirmacaoNovaSenha: null });
              setTimeout(() => {
                this.novaSenha2Element?.nativeElement.focus();
                this.processing = false;
              }, 0);
              this._alert.open(response, `Token de recuperação de senha enviado com sucesso para o e-mail "${this.email}".`);
            } else {
              this._alert.openCustomError(['Erro ao requisitar token de recuperação de senha. Aguarde 5 minutos para tentar novamente']);
              setTimeout(() => {
                this.router.navigate(['/']);
              }, 1000);
            }
          },
          (error: any) => {
            this._alert.openCustomError(['Erro ao requisitar token de recuperação de senha. Aguarde 5 minutos para tentar novamente']);
            setTimeout(() => {
              this.router.navigate(['/']);
            }, 1000);
          }
        );
    } else {
      this._alert.openCustomError(['Erro ao requisitar token de recuperação de senha. Aguarde 5 minutos para tentar novamente']);
      this.router.navigate(['/']);
    }
  }

  getErrorToken() {
    return this.formGroup2.get('Token')?.hasError('required') ? 'Token é obrigatório' : '';
  }

  getErrorNovaSenha() {
    return this.formGroup2.get('NovaSenha')?.hasError('required') ? 'Nova senha é obrigatória' :
      this.formGroup2.get('NovaSenha')?.hasError('notComplex') ? 'Nova senha não possui a complexidade exigida' : '';

  }

  getErrorConfirmacaoNovaSenha() {
    return this.formGroup2.get('ConfirmacaoNovaSenha')?.hasError('required') ? 'Confirmação de nova senha é obrigatória' :
      this.formGroup2.get('ConfirmacaoNovaSenha')?.hasError('notSame') ? 'Confirmação de nova senha não confere' : '';
  }

  onSubmitPhase2(post: any) {
    this.processing = true;
    var request = new ResetPasswordRequest(post.Email, post.Token, post.NovaSenha);
    this.authService.resetPassword(request).subscribe(
      (response: any) => {
        if (response.success === true) {
          this._alert.open(response, 'Senha alterada com sucesso');
          this.router.navigate(['/']);
        } else {
          this._alert.open(response);
          this.retry();
        }
      }, (error) => {
        this._alert.open(error.error);
        this.retry();
      }
    );
  }

  clearConfirmacaoNovaSenha() {
    this.formGroup2.controls.ConfirmacaoNovaSenha.reset();
    this.formGroup2.controls.ConfirmacaoNovaSenha.markAsUntouched();
  }
}