export enum Roles {
    admin = 'UI.Admin',
    myUserView = 'UI.MyUser.View',
    myUserEdit = 'UI.MyUser.Edit',
    usersCreate = 'UI.Users.Create',
    groupsView = 'UI.Groups.View',
    groupsEdit = 'UI.Groups.Edit',
    groupsCreate = 'UI.Groups.Create',
    groupsDelete = 'UI.Groups.Delete',
    clientsView = 'UI.Clients.View',
    clientsEdit = 'UI.Clients.Edit',
    clientsCreate = 'UI.Clients.Create',
    clientsDelete = 'UI.Clients.Delete',
    groupsAndClientsManage = 'UI.Clients.Manage',
    customView = 'UI.Custom.View',
    customEdit = 'UI.Custom.Edit',
    customCreate = 'UI.Custom.Create',
    customDelete = 'UI.Custom.Delete',
    requestView = 'UI.Request.View',
    requestCreate = 'UI.Request.Create',
    requestApprove = 'UI.Request.Approve',
    requestEdit = 'UI.Request.Edit',
    requestDelete = 'UI.Request.Delete',
    editEndContract = 'UI.DataContrato.Editar'
}