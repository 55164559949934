import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AuthService, LOGIN_TYPES } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  /**
   *
   */
  constructor(
    private readonly authService: AuthService,
    private readonly msalGuard: MsalGuard,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.authService.getLoginType() === LOGIN_TYPES.LOCAL) {
      if (this.authService.isTokenPresent()) {
        return this.authService.verifyToken()
          .pipe(
            switchMap((result) => {
              if (result) return of(true);
              return this.authService.logout();
            })
          );
      }
    } else if (this.authService.getLoginType() === LOGIN_TYPES.ACN_ESO) {
      if (this.authService.isTokenPresent()) {
        return this.authService.verifyToken()
          .pipe(
            switchMap((result) => {
              if (result) return this.msalGuard.canActivate(route, state)
                .pipe(
                  switchMap((result2) => {
                    if (result2) return of(true);
                    return this.authService.logout();
                  })
                );
              return this.authService.logout();
            }),
          );
      };
    }
    return this.authService.logout();
  }
}
