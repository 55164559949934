export const environment = {
  production: false,
  api: 'https://tlfbpo.azure-api.net/rcb-pcc/dev',
  redirectUrl: `https://tfl.dev.portalclientecustomizado.accenture.com/`,
  msalUrl: `https://tfl.dev.portalclientecustomizado.accenture.com/`,
  signInAuthority: `https://login.microsoftonline.com/f3211d0e-125b-42c3-86db-322b19a65a22`,
  authorityDomain: `f3211d0e-125b-42c3-86db-322b19a65a22`,
  b2cClientId: `10efd5b6-ab25-4088-bd36-876527040a25`,
  getTokenScope: `10efd5b6-ab25-4088-bd36-876527040a25/.default`,
  empresa1: 7,
  empresa2: 8,
  scopes: ['openid', 'profile', 'email', 'user.read'],
};