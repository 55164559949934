<div class="p-5" *ngIf="State === STATES.PHASE1">
    <h2>Autenticação - Identificação do Usuário</h2>
    <mat-card class="p-card mat-elevation-z0">
        <div class="container">
            <form [formGroup]="formGroup1" (ngSubmit)="onSubmitPhase1(formGroup1.getRawValue())" class="form">
                <div class="row">
                    <mat-form-field class="form-element col-12 col-lg-6">
                        <mat-label>E-mail</mat-label>
                        <input matInput placeholder="E-mail" #email1 formControlName="Email" maxlength="50">
                        <mat-error *ngIf="!formGroup1.controls['Email'].valid && formGroup1.controls['Email'].touched">
                            {{ getErrorEmail() }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="row">
                    <div
                        class="form-element col-12 col-lg-2 offset-lg-4 d-flex flex-lg-row-reverse justify-content-center justify-content-lg-start mt-3">
                        <button mat-raised-button color="primary" type="submit" class="button"
                            [disabled]="!formGroup1.valid || processing">Avançar
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </mat-card>
</div>

<div class="p-5" *ngIf="State === STATES.PHASE2">
    <h2>Autenticação - Verificação de Senha</h2>
    <mat-card class="p-card mat-elevation-z0">
        <div class="container">
            <form [formGroup]="formGroup2" (ngSubmit)="onSubmitPhase2(formGroup2.getRawValue())" class="form">
                <div class="row">
                    <mat-form-field class="form-element col-12 col-lg-6">
                        <mat-label>E-mail</mat-label>
                        <input matInput placeholder="E-mail" formControlName="Email" readonly>
                    </mat-form-field>
                </div>
                <div class="row">
                    <mat-form-field class="form-element col-12 col-lg-6">
                        <mat-label>Senha</mat-label>
                        <input matInput placeholder="Senha" #senha2 formControlName="Senha" maxlength="50"
                            type="password">
                        <mat-error
                            *ngIf="!formGroup2.controls['Senha']?.valid && formGroup2.controls['Senha']?.touched">
                            {{ getErrorSenha() }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="row">
                    <div
                        class="form-element col-12 col-lg-2 d-flex justify-content-center justify-content-lg-start mt-3">
                        <button mat-raised-button color="disabled" class="button" type="button" (click)="cancelLogin()"
                            [disabled]="processing">Cancelar
                        </button>
                    </div>
                    <div class="form-element col-12 col-lg-2 d-flex justify-content-center mt-3">
                        <button mat-raised-button color="disabled" class="button" type="button"
                            (click)="recoverPassword()" [disabled]="processing">Esqueci a senha
                        </button>
                    </div>
                    <div
                        class="form-element col-12 col-lg-2 d-flex flex-lg-row-reverse justify-content-center justify-content-lg-start mt-3">
                        <button mat-raised-button color="primary" type="submit" class="button"
                            [disabled]="!formGroup2.valid || processing">Avançar
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </mat-card>
</div>

<div class="p-5" *ngIf="State === STATES.PHASE3">
    <h2>Autenticação - Verificação de Token</h2>
    <mat-card class="p-card mat-elevation-z0">
        <div class="container">
            <form [formGroup]="formGroup3" (ngSubmit)="onSubmitPhase3(formGroup3.getRawValue())" class="form">
                <div class="row">
                    <mat-form-field class="form-element col-12 col-lg-6">
                        <mat-label>E-mail</mat-label>
                        <input matInput placeholder="E-mail" formControlName="Email" readonly>
                    </mat-form-field>
                </div>
                <div class="row">
                    <mat-form-field class="form-element col-12 col-lg-6">
                        <mat-label>Senha</mat-label>
                        <input matInput placeholder="Senha" formControlName="Senha" readonly type="password">
                    </mat-form-field>
                </div>
                <div class="row">
                    <mat-form-field class="form-element col-12 col-lg-6">
                        <mat-label>Token</mat-label>
                        <input matInput placeholder="Token" #token3 formControlName="Token" maxlength="50">
                        <mat-error *ngIf="!formGroup3.controls['Token'].valid && formGroup3.controls['Token'].touched">
                            {{ getErrorToken() }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="row">
                    <div
                        class="form-element col-12 col-lg-3 d-flex justify-content-center justify-content-lg-start mt-3">
                        <button mat-raised-button color="disabled" class="button" type="button" (click)="cancelLogin()"
                            [disabled]="processing">Cancelar
                        </button>
                    </div>
                    <div
                        class="form-element col-12 col-lg-3 d-flex flex-lg-row-reverse justify-content-center justify-content-lg-start mt-3">
                        <button mat-raised-button color="primary" type="submit" class="button"
                            [disabled]="!formGroup3.valid || processing">Entrar
                        </button>

                    </div>
                </div>
            </form>
        </div>
    </mat-card>
</div>